
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form, Dropdown, Modal, Spinner } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { Link } from "react-router-dom";
import config from "../../../../config";
import Header from "../../../commanComponents/usernavbar";
import "../../css/createcheque.css";
import { addBankDeposit, getBankAccountList } from '../../../../Action/propertyOwner.action'
import { toast, Toaster } from "react-hot-toast";
import { FaPlus, FaArrowLeftLong } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";

const Addbankdeposit = () => {
  createTheme('solarized', {
    text: {
      primary: '#000',
      secondary: '#2aa198',
    },
    background: {
      default: '',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#EBEBEB',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const customStyles = {
    rows: {
      style: {
        minHeight: '70px', // override the row height

      },
    },
    headCells: {

      style: {
        minHeight: '60px',
        paddingLeft: '20px', // override the cell padding for head cells
        paddingRight: '20px',
        background: '#FFF8F4',

      },
    },
    cells: {
      style: {
        paddingLeft: '20px', // override the cell padding for data cells
        paddingRight: '20px',
      },
    },
  };

  const navigate = useNavigate();


  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1, // Serial number based on the index
      sortable: false,
      width: '8%',
    },
    {
      name: 'Received From',
      selector: row => row.receviedFrom,
      width: "14%"
    },
    {
      name: 'Account',
      selector: row => row.account,
      width: "14%"
    },
    {
      name: 'Description',
      selector: row => row.description,
      width: "15%"
    },
    {
      name: 'Payment Method',
      selector: row => row.paymentMethod,
      width: "14%"
    },
    {
      name: 'Ref No',
      selector: row => row.referenceNo,
      width: "13.5%"
    },
    {
      name: 'Amount',
      selector: row => '$' + row.amount,
      width: "13%"
    },
    {
      name: 'Delete',
      selector: row => row.status,
      cell: (row, index) => (
        <Button
          variant="default"
          className="p-0 btn-sm text-primary"
          onClick={() => handleDelete(index)} // Pass the index to the delete handler
        >
          <RiDeleteBin6Line size={`15px`} />
        </Button>
      ),
    },
  ];

  useEffect(() => {
    // Add the class to the body tag
    document.body.classList.add("staffbody");

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove("staffbody");
    };
  }, []);


  const [formData, setFormData] = useState({
    bankAccountId: '',
    date: '',
    tag: '',
    memo: '',
    depositArr: []
  });
  const [errors, setErrors] = useState({});
  const [accountList, setAccountList] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [estimateError, setEstimateError] = useState({});
  const [loading, setLoading] = useState(false);
  const [estimate, setEstimate] = useState({
    receviedFrom: '',
    description: "",
    paymentMethod: "",
    account: "",
    amount: "",
    referenceNo: '',
  });
  const [fileName, setFileName] = useState("");



  useEffect(() => {
    fetchAccountList()
  }, [])


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, attachment: e.target.files[0] });
    setFileName(e.target.files ? e.target.files[0].name : "");
  };

  const validateForm = ({ bankAccountId, date, tag }) => {
    const errors = {};

    if (!bankAccountId) {
      errors.bankAccountId = "Account is required";
    }
    if (!date) {
      errors.date = "Date is required";
    }
    if (!tag) {
      errors.tag = "Tag is required";
    }

    if (formData.depositArr.length === 0) errors.depositArr = "At least one item is required";


    return errors;
  };

  const fetchAccountList = async () => {
    try {
      let res = await getBankAccountList({ type: 1 })
      if (res.success) {
        setAccountList(res.data || [])
      }
    } catch (error) {

    }
  }

  const validateProduct = () => {
    let estimateError = {};
    if (!estimate.receviedFrom) estimateError.receviedFrom = "Received from is required";
    if (!estimate.account) estimateError.account = "Account is required";
    if (!estimate.description) estimateError.description = "Description is required";
    if (!estimate.paymentMethod) estimateError.paymentMethod = "Payment Method is required";
    if (!estimate.referenceNo) estimateError.referenceNo = "Reference number is required";
    if (!estimate.amount) estimateError.amount = "Amount is required";
    return estimateError;
  };

  const handleDelete = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      depositArr: prevData.depositArr.filter((_, i) => i !== index),
    }));
  };


  const handleAddProduct = () => {
    const productValidationErrors = validateProduct();
    if (Object.keys(productValidationErrors).length === 0) {
      setFormData((prevData) => ({
        ...prevData,
        depositArr: [...(prevData.depositArr || []), estimate],
      }));
      console.log('formData', formData)
      setEstimate({
        receviedFrom: '',
        description: '',
        paymentMethod: '',
        account: '',
        amount: '',
        referenceNo: ''
      });
      setShowModal(false);
      setEstimateError({});
    } else {
      setEstimateError(productValidationErrors);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm(formData);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) return;

    setLoading(true);

    try {

      let data = {
        ...formData,
        depositArr: JSON.stringify(formData.depositArr),
        totalAmount: calculateTotalAmount().toFixed(2),
        type: 1,
      }

      const res = await addBankDeposit(data);

      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          navigate(`/bankdepositelist`);
        }, 2000);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      console.error("API error:", error);
      alert("Server Error");
    } finally {
      setLoading(false);
    }
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setEstimate((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const calculateTotalAmount = () => {
    return formData.depositArr.reduce((total, deposite) => {
      return total + parseFloat(deposite.amount || 0);
    }, 0);
  };

  return (
    <div className="staffdashboard bg-pink">
      <Header />
      <Toaster />

      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <Row>
            <Col lg={5} sm={12} xs={12} className="mb-4">
              <Link to={`${config.baseurl}bankdepositelist`}>
                <Button variant="default" className="p-0 h-auto">
                  <FaArrowLeftLong size={`25px`} />
                </Button>
              </Link>
            </Col>
            <Col lg={7} sm={12} xs={12} className="text-right"></Col>
          </Row>
          <div className="">
            <Col lg={12} className="mb-3">
              <Card className="border-0 bg-white p-3">
                <Card.Header className="mb-3 pb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mb-2"><h4 className="mb-0 fw-bolder">Add bank deposits</h4></div>

                  </div>

                </Card.Header>
                <Link to={`${config.baseurl}createaccount`}>
                  <Button
                    variant="primary"
                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                  >
                    Add Account
                  </Button>
                </Link>
                <Card.Body className="p-0">
                  <div className="">
                    <Row>
                      <Col xl={12} lg={12} className="">
                        <Row className="mt-2">

                          <Col lg={6} className="mb-4">
                            <Form.Group controlId="formBasicExpense">
                              <Form.Label className="text-light-grey mb-1">Bank Account</Form.Label>
                              <Form.Control
                                as="select"
                                name="bankAccountId"
                                value={formData?.bankAccountId}
                                onChange={handleChange}
                              >
                                <option value="">Select bank account</option>
                                {accountList.map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.accountName}
                                  </option>
                                ))}
                              </Form.Control>
                              {errors.bankAccountId && <small className="text-danger">{errors.bankAccountId}</small>}
                            </Form.Group>
                          </Col>

                          <Col lg={6} className="mb-4">
                            <Form.Group>
                              <Form.Label>Date</Form.Label>
                              <Form.Control
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                isInvalid={!!errors.date}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.date}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} className="mb-4">
                            <Form.Group>
                              <Form.Label>Tag</Form.Label>
                              <Form.Control
                                type="text"
                                name="tag"
                                value={formData.tag}
                                onChange={handleChange}
                                isInvalid={!!errors.tag}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.tag}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={6} className="mb-4">
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12}>
                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                      </Col>

                      <Col xl={12} lg={12} className="">
                        <div className="staff_upcoming_booking">
                          <div className="border-0">
                            <div className="p-0">
                              <div className=" mb-3">
                                <div className=""><h4 className="mb-0 fw-bolder">Add Funds to this deposit </h4></div>
                              </div>
                              <div>
                                <Row className="align-items-center">
                                  <Col lg={12} xs={12}>
                                    <div className="pt-2">
                                      <DataTable
                                        columns={columns}
                                        data={formData.depositArr || []} // Binding to dynamic data
                                        customStyles={customStyles}
                                        theme="solarized"
                                      />

                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="mt-3">
                                <Link to={`#`}>
                                  <Button
                                    variant="outline-primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                    onClick={() => setShowModal(true)}
                                  >
                                    Add lines
                                  </Button>
                                </Link>
                                <Link to={`#`}>
                                  <Button
                                    variant="primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                    onClick={() => setFormData({
                                      ...formData,
                                      depositArr: []
                                    })}
                                  >
                                    Clear All Lines
                                  </Button>
                                </Link>
                              </div>
                              {errors.depositArr && <small className="text-danger">{errors.depositArr}</small>}
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg={12}>
                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                      </Col>

                      <Col xl={12}>
                        <div className="">
                          <Row className="align-items-center">
                            <Col lg={6} className="mb-4">
                              <Form.Group>
                                <Form.Label className="text-light-grey mb-1">Memo</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="memo"
                                  value={formData.memo}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                            </Col>

                            <Col xl={4} lg={4} md={8} className="mb-4">
                            </Col>
                            <Col xl={2} lg={2} md={4} className="mb-4">
                              <div className="d-flex justify-content-end justify-content-between">
                                <h5>Total Amount: ${calculateTotalAmount().toFixed(2)}</h5>
                              </div>
                            </Col>
                          </Row>
                          {/* ----------Balance sheet----------- */}
                          <div className="tableBody p-0" >
                            <Row className="justify-content-center">
                              <Col xl={4} lg={4} md={6}>
                                {/* ----------Image upload start-------------- */}
                                <div className="text-center py-sm-5 mx-auto">
                                  <div className="fileUpload">
                                    <Form.Control
                                      type="file"
                                      id="base-input"
                                      className="form-input form-style-base"
                                      //  value={formData.attachment}
                                      onChange={handleFileChange}
                                      accept="image/*,application/pdf" // Accept images and PDFs, customize if needed
                                    />
                                    <div className="text-center">
                                      <i className="svgIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40" fill="none">
                                          <path d="M31.7099 33.75C31.7099 37.1964 28.9062 40 25.4599 40H6.25C2.80365 40 0 37.1964 0 33.75V9.29688C0 5.85052 2.80365 3.04688 6.25 3.04688H6.54694C7.21344 1.26892 8.92975 0 10.9375 0C13.5223 0 15.625 2.10266 15.625 4.6875V15.7031C15.625 18.288 13.5223 20.3906 10.9375 20.3906C8.35266 20.3906 6.25 18.288 6.25 15.7031V10.8594C6.25 9.99634 6.94946 9.29688 7.8125 9.29688C8.67554 9.29688 9.375 9.99634 9.375 10.8594V15.7031C9.375 16.5646 10.076 17.2656 10.9375 17.2656C11.799 17.2656 12.5 16.5646 12.5 15.7031V4.6875C12.5 3.82599 11.799 3.125 10.9375 3.125C10.2069 3.125 9.59381 3.62976 9.42352 4.30817C9.44244 4.40582 9.45312 4.50623 9.45312 4.60938C9.45312 5.06073 9.26056 5.46631 8.95447 5.75134C8.66943 6.05743 8.26385 6.25 7.8125 6.25C7.64252 6.25 7.47955 6.22192 7.32635 6.17188H6.25C4.52698 6.17188 3.125 7.57385 3.125 9.29688V33.75C3.125 35.473 4.52698 36.875 6.25 36.875H25.4602C27.1832 36.875 28.5852 35.473 28.5852 33.75C28.5852 32.887 29.2847 32.1875 30.1477 32.1875C31.0104 32.1875 31.7099 32.887 31.7099 33.75ZM7.73438 29.8438C6.87134 29.8438 6.17188 30.5432 6.17188 31.4062C6.17188 32.2693 6.87134 32.9688 7.73438 32.9688H19.1406C20.0037 32.9688 20.7031 32.2693 20.7031 31.4062C20.7031 30.5432 20.0037 29.8438 19.1406 29.8438H7.73438ZM25.3906 25.0781C25.3906 24.2151 24.6912 23.5156 23.8281 23.5156H7.73438C6.87134 23.5156 6.17188 24.2151 6.17188 25.0781C6.17188 25.9412 6.87134 26.6406 7.73438 26.6406H23.8281C24.6912 26.6406 25.3906 25.9412 25.3906 25.0781ZM25.4599 3.04688H20.2344C19.3713 3.04688 18.6719 3.74634 18.6719 4.60938C18.6719 5.47241 19.3713 6.17188 20.2344 6.17188H25.4599C27.1832 6.17188 28.5849 7.57385 28.5849 9.29688V25.9375C28.5849 26.8005 29.2847 27.5 30.1474 27.5C31.0104 27.5 31.7099 26.8005 31.7099 25.9375V9.29688C31.7099 5.85052 28.9062 3.04688 25.4599 3.04688Z" fill="#E46422" />
                                        </svg>
                                      </i>
                                      <h6 className="text-primary mt-1">Attachments</h6>
                                      {fileName && <p className="mt-2">{fileName}</p>}
                                    </div>
                                  </div>

                                  <div className="mt-5">
                                    <Link to={`#`}>
                                      <Button
                                        variant="outline-primary"
                                        className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                      >
                                        Cancel
                                      </Button>
                                    </Link>
                                    <Link to={`#`}>
                                      <Button
                                        variant="primary"
                                        className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                        onClick={handleSubmit}
                                        disabled={loading}
                                      >
                                        {loading ? (
                                          <>
                                            <Spinner
                                              as="span"
                                              animation="border"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                            />
                                            {" "}Saving...
                                          </>
                                        ) : (
                                          "Save & Close"
                                        )}
                                      </Button>
                                    </Link>

                                  </div>
                                </div>
                                {/* ----------Image upload end-------------- */}
                              </Col>
                            </Row>



                          </div>

                        </div>
                      </Col>
                    </Row>
                  </div>


                </Card.Body>
              </Card>
            </Col>
          </div>

        </Container>
      </section>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Funds to this deposit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group className="mb-3">
              <Form.Label>Received From</Form.Label>
              <Form.Control
                type="text"
                name="receviedFrom"
                value={estimate.receviedFrom}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {estimateError.receviedFrom && (
                <p className="text-danger">{estimateError.receviedFrom}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Account</Form.Label>
              <Form.Control
                type="text"
                name="account"
                value={estimate.account}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {estimateError.account && (
                <p className="text-danger">{estimateError.account}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={estimate.description}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {estimateError.description && (
                <p className="text-danger">{estimateError.description}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Payment Method</Form.Label>
              <Form.Control
                type="text"
                name="paymentMethod"
                value={estimate.paymentMethod}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {estimateError.paymentMethod && (
                <p className="text-danger">{estimateError.paymentMethod}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Reference Number</Form.Label>
              <Form.Control
                type="text"
                name="referenceNo"
                value={estimate.referenceNo}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {estimateError.referenceNo && (
                <p className="text-danger">{estimateError.referenceNo}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={estimate.amount}
                onChange={handleProductChange}
                className="rounded-5"
              />
              {estimateError.amount && (
                <p className="text-danger">{estimateError.amount}</p>
              )}
            </Form.Group>
            <Button
              variant="primary"
              onClick={handleAddProduct}
              className="rounded-5"
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Addbankdeposit;
