import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form, Modal } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from "react-router-dom";
import Header from "../../../commanComponents/usernavbar";
import "../../css/staffdashboard.css";
import config from "../../../../config";
import "../../css/finance.css";
import { toast, Toaster } from "react-hot-toast";
import { GoPencil } from "react-icons/go";
import { getBankAccountList, addBankAccountDetails, getAccountTypeList, getBankAccountListById } from "../../../../Action/propertyOwner.action";

const customStyles = {
    rows: {
        style: {
            minHeight: '70px', // override the row height
        },
    },
    headCells: {
        style: {
            minHeight: '60px',
            paddingLeft: '20px', // override the cell padding for head cells
            paddingRight: '20px',
            background: '#FFF8F4',
        },
    },
    cells: {
        style: {
            paddingLeft: '20px', // override the cell padding for data cells
            paddingRight: '20px',
        },
    },
};

const Createaccount = () => {

    useEffect(() => {
        // Add the class to the body tag
        document.body.classList.add("staffbody");

        // Cleanup function to remove the class when the component is unmounted
        return () => {
            document.body.classList.remove("staffbody");
        };
    }, []);

    const [accountList, setAccountList] = useState([]);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [accountTypeList, setAccountTypeList] = useState([])
    const [accountName, setAccountName] = useState([])

    useEffect(() => {
        fetechAccountList();
        fetchAccountTypeList()
    }, []);

    const fetechAccountList = async () => {
        let res = await getBankAccountList();
        if (res.success) {
            setAccountList(res.data || []);
        }
    };

    const fetchAccountTypeList = async () => {
        let res = await getAccountTypeList();
        if (res.success) {
            setAccountTypeList(res.data || []);
        }
    };


    const fetchBankAccount = async () => {
		try {
			let res = await getBankAccountListById({accountType: formData.accountType})
			if (res.success) {
				setAccountName(res.data || [])
			}
		} catch (error) {

		}
	}

    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Account Name',
            selector: row => row.accountName,
        },
        {
            name: 'Account Type',
            selector: row => row.name,
        },
        // {
        //     name: 'Detail Type',
        //     selector: row => row.detailType,
        // },
        {
            name: 'Opening Balance',
            selector: row => '$' + row.openingBalance,
        },
    ];

    const modelClose = () => {
        setFormData({
            // accountNumber: '',
            subAccount: '',
            accountName: '',
            accountType: '',
            detailType: '',
            description: '',
            openingBalance: '',
            asOf: ''
        });
        setIsModelOpen(false);
    };

    // const inputHandler = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((old) => ({ ...old, [name]: value }));

    //     if (value.trim() !== "") {
    //         setErrors((oldErrors) => ({ ...oldErrors, [name]: "" }));
    //     }
    // };

    const inputHandler = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === "checkbox" ? (checked ? 1 : 0) : value;

        setFormData((old) => ({ ...old, [name]: inputValue }));

        if (typeof inputValue === "string" && inputValue.trim() !== "") {
            setErrors((oldErrors) => ({ ...oldErrors, [name]: "" }));
        }
    };


    const validateForm = () => {
        let formErrors = {};
        //if (!formData.accountNumber) formErrors.accountNumber = "Account Number is required.";
        if (!formData.accountName) formErrors.accountName = "Account Name is required.";
        if (!formData.accountType) formErrors.accountType = "Please select an Account Type.";
        //if (!formData.detailType) formErrors.detailType = "Detail type is required.";
        // if (!formData.description) formErrors.description = "Description is required.";
        // if (!formData.openingBalance) formErrors.openingBalance = "Opening Balance is required.";
        // if (!formData.asOf) formErrors.asOf = "As Of date is required.";

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form default submission behavior
        if (!validateForm()) return;

        try {
            setIsLoading(true);
            let data = {
                ...formData,
            };

            let res;
            if (isEdit) {
                res = await addBankAccountDetails(data);
            } else {
                res = await addBankAccountDetails(data);
            }

            if (res.success) {
                toast.success(res.msg);
                fetechAccountList();
                modelClose();
            } else {
                toast.error(res.msg);
            }

            setIsLoading(false);
        } catch (error) {
            toast.error('Something went wrong. Please try again later.');
            setIsLoading(false);
        }
    };

    const handleEdit = (employeeDetails) => {
        setFormData(employeeDetails);
        setIsEdit(true);
        setIsModelOpen(true);
    };

    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <Toaster />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <Card className="border-0 bg-white p-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div><h4 className="fw-bolder mb-4">Chart of Account</h4></div>
                            <div className="text-end">
                                <Button className="rounded-5 px-4 px-xs-1 mb-4 mobileBtn" onClick={() => {
                                    setFormData({});
                                    setIsEdit(false);
                                    setIsModelOpen(true);
                                }}> Add New Account </Button>
                            </div>
                        </div>
                        <Card.Body >
                            <div className="chartofaccount">
                                {accountList.length > 0 ?
                                    <Row>
                                        <Col lg={12}>
                                            <div className="mb-4">
                                                <DataTable
                                                    columns={columns}
                                                    data={accountList}
                                                    customStyles={customStyles}
                                                    theme="solarized"
                                                    pagination
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <div className="text-center">
                                        <div>
                                            <img src="images/nodatafound.svg" alt="No data found" />
                                        </div>
                                        <h5 className="mb-1">Account Report</h5>
                                        <div>This report doesn’t have any data</div>
                                    </div>
                                }
                            </div>
                        </Card.Body>
                    </Card>

                    <Modal
                        show={isModelOpen}
                        onHide={modelClose}
                        dialogClassName="modal-90w modal-xl"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <b>{isEdit ? "Edit Account" : "Add Account"}</b>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleSubmit}>
                                <Row className="mt-2">
                                    {/* <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Label className="text-light-grey">Account Number</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="accountNumber"
                                                value={formData.accountNumber}
                                                onChange={inputHandler}
                                                isInvalid={!!errors.accountNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.accountNumber}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col> */}

                                    <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Label className="text-light-grey">Account Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="accountName"
                                                value={formData.accountName}
                                                onChange={inputHandler}
                                                isInvalid={!!errors.accountName}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.accountName}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                   
                                    <Col lg={6} className="mb-4">
                                        <Form.Group controlId="formBasicExpense" className="mb-3">
                                            <Form.Label className="text-light-grey mb-1">Account Type</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="accountType"
                                                value={formData?.accountType}
                                                onChange={inputHandler}
                                                onClick={() => {
                                                    fetchBankAccount();
                                                }}
                                            >
                                                <option value="">Select Account Type</option>
                                                {accountTypeList.map(item => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{errors.accountType}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} className="mb-4">
                                        <Form.Group controlId="formBasicExpense" className="mb-3">
                                            <Form.Label className="text-light-grey mb-1">Detail Type</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="detailType"
                                                value={formData?.detailType}
                                                onChange={inputHandler}
                                            >
                                                <option value="">Select Account Type</option>
                                                {accountName.map(item => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.accountName}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            {/* <Form.Control.Feedback type="invalid">{errors.detailType}</Form.Control.Feedback> */}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Label className="text-light-grey mb-1">Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="description"
                                                value={formData.description}
                                                onChange={inputHandler}
                                                isInvalid={!!errors.description}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback> */}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                id="subAccount"
                                                name="subAccount"
                                                label="Make this as sub account"
                                                checked={formData.subAccount}  // Make sure `subAccount` is in formData with true/false value
                                                onChange={(e) => inputHandler({ target: { name: 'subAccount', value: e.target.checked ? 1 : 0 } })}
                                                isInvalid={!!errors.subAccount}
                                            />
                                        </Form.Group>
                                    </Col>


                                    <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Label className="text-light-grey mb-1">Opening Balance</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="openingBalance"
                                                value={formData.openingBalance}
                                                onChange={inputHandler}
                                                isInvalid={!!errors.openingBalance}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">{errors.openingBalance}</Form.Control.Feedback> */}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} className="mb-4">
                                        <Form.Group>
                                            <Form.Label className="text-light-grey mb-1">As Of</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="asOf"
                                                value={formData.asOf}
                                                onChange={inputHandler}
                                                isInvalid={!!errors.asOf}
                                            />
                                            {/* <Form.Control.Feedback type="invalid">{errors.asOf}</Form.Control.Feedback> */}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button variant="primary" type="submit" disabled={isLoading}>
                                    {isEdit ? "Update" : "Submit"}
                                </Button>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </Container>
            </section>
        </div>
    );
};

export default Createaccount;
