import { OwnerGetRequest, postRequest, OwnerPostRequest ,getRequest ,propertyOwnerRequestForm, OwnerPutRequest} from "../coreFIles/helper";

export const registerSEOAction = (data) => {
  return OwnerPostRequest('registerPreLaunching', data).then(res => { return res.data })
}

export const RegisterAction = (data) => {
  return OwnerPostRequest('registration', data).then(res => { return res.data })
}

export const SmsVerificationAction = (countryCode, mobileNumber, email) => {
  const data = {
    countryCode : countryCode,
    mobileNumber: mobileNumber,
    email:email
  };
  return OwnerPostRequest('smsVerification', data).then(res => { return res.data })
}

export const LoginAction = (data) => {
  return OwnerPostRequest('login', data).then(res => { return res.data })
}

export const getCountryCodeAction = (data) => {
  return OwnerGetRequest('getCountry', data).then(res => { return res.data })
}

export const ForgotPasswordAction = (data) => {
  return OwnerPostRequest('forgotPassword', data).then(res => { return res.data })
}

export const verifyForgetPasswordOTPAction = (data) => {
  return OwnerPostRequest('verifyForgetPasswordOTP', data).then(res => { return res.data })
}

export const ResetPasswordAction = (data) => {
  return OwnerPostRequest('resetpassword', data).then(res => { return res.data })
}

export const getSubscriptionPlanAction = (data)=>{
  return getRequest('getSubscriptionDetail' , data).then(res=>{return res.data})
}
export const getSubscriptionPlanAction1 = (data)=>{
  return OwnerGetRequest('getSubscriptionDetail' , data).then(res=>{return res.data})
}

export const googleLoginAction= (data)=>{
  return OwnerPostRequest('googleLogin' , data).then(res=>{return res.data})
}

export const InsertPaymentDetailsAction=(data)=>{
  return OwnerPostRequest('buySubscription' , data).then(res=>{return res.data})
}

export const getPropertyBookingDetailsAction =(data)=>{
  return OwnerPostRequest('getPropertyBookingDetails' , data).then(res=>{return res.data})
}

export const insertSupportRequestAction =(data)=>{
  return propertyOwnerRequestForm('insertSupportRequest' , data).then(res=>{return res.data})
}

export const getSupportRequestAction =(data)=>{
  return OwnerPostRequest('getSupportRequest' , data).then(res=>{return res.data})
}

export const checkPlanPurchaseAction =(data)=>{
  return OwnerPostRequest('checkPlanPurchase' , data).then(res=>{return res.data})
}

export const paymentIntentAction =(data)=>{
  return OwnerPostRequest('paymentIntent' , data).then(res=>{return res.data})
}

export const getRecentChatAction =(data)=>{
  return OwnerGetRequest('getRecentChat' , data).then(res=>{return res.data})
}

export const getSupportChatDetailsAction =(data)=>{
  return OwnerPostRequest('getSupportChatDetails' , data).then(res=>{return res.data})
}

export const insertChatMsg =(data)=>{
  return OwnerPostRequest('insertChat' , data).then(res=>{return res.data})
}

export const getHostRequestAction =(data)=>{
  return OwnerGetRequest('getHostRequest' , data).then(res=>{return res.data})
}
export const getPropertyDetailByIdAction =(data)=>{
  return OwnerPostRequest('getPropertyDetailById' , data).then(res=>{return res.data})
}

export const removeImageAction =(data)=>{
  return OwnerPostRequest('removeImage' , data).then(res=>{return res.data})
}

export const getPropertyStandoutemenitiesByIdAction =(data)=>{
  return OwnerPostRequest('getPropertyStandoutemenitiesById' , data).then(res=>{return res.data})
}
export const editGeneralInfoOfPropertyListingAction =(data)=>{
  return OwnerPostRequest('editGeneralInfoOfPropertyListing' , data).then(res=>{return res.data})
}
export const editPropertyListingLocationAction =(data)=>{
  return OwnerPostRequest('editPropertyListingLocation' , data).then(res=>{return res.data})
}
export const setCheckInAndCheckOutTimeAction =(data)=>{
  return OwnerPostRequest('setCheckInAndCheckOutTime' , data).then(res=>{return res.data})
}
export const editPricingOfPropertyListingAction =(data)=>{
  return OwnerPostRequest('editPricingOfPropertyListing' , data).then(res=>{return res.data})
}
export const editPropertyAndRoomsOfListingAction =(data)=>{
  return OwnerPostRequest('editPropertyAndRoomsOfListing' , data).then(res=>{return res.data})
}
export const editAmenitiesOfPropertyListingAction =(data)=>{
  return OwnerPostRequest('editAmenitiesOfPropertyListing' , data).then(res=>{return res.data})
}
export const editEnvironmentOfListingPropertyAction =(data)=>{
  return OwnerPostRequest('editEnvironmentOfListingProperty' , data).then(res=>{return res.data})
}
export const editeditRoomAndSpacesPropertyAction =(data)=>{
  return OwnerPostRequest('editRoomAndSpacesProperty' , data).then(res=>{return res.data})
}
export const updateCancellationPolicyOfPropertyAction =(data)=>{
  return OwnerPostRequest('updateCancellationPolicyOfProperty' , data).then(res=>{return res.data})
}
export const updateHouseRulesOfPropertyAction =(data)=>{
  return OwnerPostRequest('updateHouseRulesOfProperty' , data).then(res=>{return res.data})
}
export const updateCancellationPolicyAction =(data)=>{
  return OwnerPostRequest('updateCancellationPolicy' , data).then(res=>{return res.data})
}
export const updatePetsDataAction =(data)=>{
  return OwnerPostRequest('updatePetsData' , data).then(res=>{return res.data})
}
export const updatePhotoAndVideoPropertyAction =(data)=>{
  return propertyOwnerRequestForm('updatePhotoAndVideoProperty' , data).then(res=>{return res.data})
}
export const AddRoomTypeImagesAction =(data)=>{
  return propertyOwnerRequestForm('AddRoomTypeImages' , data).then(res=>{return res.data})
}
export const getSafetyDetailsByIdAction =(data)=>{
  return OwnerPostRequest('getSafetyDetailsById' , data).then(res=>{return res.data})
}
export const getPropertyPurchaseTypeAction =(data)=>{
  return OwnerGetRequest('getPropertyPurchaseType' , data).then(res=>{return res.data})
}

export const getRoomPhotosAction =(data)=>{
  return OwnerPostRequest('getRoomPhotos' , data).then(res=>{return res.data})
}

export const removeRoomsImageAction =(data)=>{
  return OwnerPostRequest('removeRoomsImage' , data).then(res=>{return res.data})
}

export const addPropertyRoomPhotosAction =(data)=>{
  return propertyOwnerRequestForm('addPropertyRoomPhotos' , data).then(res=>{return res.data})
}

export const addRoomPhotosAction =(data)=>{
  return propertyOwnerRequestForm('addRoomPhotos' , data).then(res=>{return res.data})
}

export const ContactFormAction = (data) => {
  return OwnerPostRequest('addContactUs', data).then(res => { return res.data })
}
export const userAccountDeletionAction = (data) => {
  return OwnerPostRequest('userAccountDeletion', data).then(res => { return res.data })
}

export const getRoomsTypeAction = (data) => {
  return OwnerGetRequest('getRoomType', data).then(res => { return res.data })
}

export const getRefundHistoryAction = (data) => {
  return OwnerPostRequest('getRefundHistory', data).then(res => { return res.data })
}

export const getEmployeeListAction = (data) => {
  return OwnerPostRequest('getEmployeeList', data).then(res => { return res.data })
}

export const getPaymentList = (data) => {
  return OwnerPostRequest('getPaymentList', data).then(res => { return res.data })
}

export const addPaymentDetail = (data) => {
  return OwnerPostRequest('addPaymentDetail', data).then(res => { return res.data })
}


export const updateEmployeePaymentStatus = (data) => {
  return OwnerPostRequest('updateEmployeePaymentStatus', data).then(res => { return res.data })
}

// ---------------------------|| NEW PHASE ||-------------------------------


export const addStaff = (data) => {
  return OwnerPostRequest('addStaff', data).then(res => { return res.data })
}

export const updateStaff = (data) => {
  return OwnerPostRequest('updateStaff', data).then(res => { return res.data })
}

export const updateStaffPassword = (data) => {
  return OwnerPostRequest('updateStaffPassword', data).then(res => { return res.data })
}

export const getStaffList = (data) => {
  return OwnerGetRequest('getStaffList', data).then(res => { return res.data })
}


export const deleteStaff = (data) => {
  return OwnerPostRequest('deleteStaff', data).then(res => { return res.data })
}

// EMPLOYEE

export const addEmployeeAPI = (data) => {
  return OwnerPostRequest('addEmployee', data).then(res => { return res.data })
}

export const updateEmployeeAction = (data) => {
  return OwnerPostRequest('updateEmployee', data).then(res => { return res.data })
}

// SUPPLIER 

export const addSupplier = (data) => {
  return OwnerPostRequest('addSupplier', data).then(res => { return res.data })
}

export const updatePaymentStatus = (data) => {
  return OwnerPostRequest('updatePaymentStatus', data).then(res => { return res.data })
}

export const updateSupplier = (data) => {
  return OwnerPostRequest('updateSupplier', data).then(res => { return res.data })
}

export const getSupplierList = (data) => {
  return OwnerPostRequest('getSupplierList', data).then(res => { return res.data })
}

export const getSupplierStatics = (data) => {
  return OwnerPostRequest('getSupplierStatics', data).then(res => { return res.data })
}


export const getBillList = (data) => {
  return OwnerPostRequest('getBillList', data).then(res => { return res.data })
}

export const addNewBill = (data) => {
  return OwnerPostRequest('addNewBill', data).then(res => { return res.data })
}

export const updatebill = (data) => {
  return OwnerPostRequest('updatebill', data).then(res => { return res.data })
}

export const getSupplierPaymentList = (data) => {
  return OwnerPostRequest('getSupplierPaymentList', data).then(res => { return res.data })
}

export const addSupplierPaymentDetail = (data) => {
  return OwnerPostRequest('addSupplierPaymentDetail', data).then(res => { return res.data })
}

export const updateSupplierPaymentDetail = (data) => {
  return OwnerPostRequest('updateSupplierPaymentDetail', data).then(res => { return res.data })
}



// EXPENSE 

export const getExpenseCategoryList = (data) => {
  return OwnerPostRequest('getExpenseCategoryList', data).then(res => { return res.data })
}

export const addExpense = (data) => {
  return OwnerPostRequest('addExpense', data).then(res => { return res.data })
}

export const updateExpense = (data) => {
  return OwnerPostRequest('updateExpense', data).then(res => { return res.data })
}

export const getExpenseList = (data) => {
  return OwnerPostRequest('getExpenseList', data).then(res => { return res.data })
}

export const getPropertyList = (data) => {
  return OwnerGetRequest('getPropertyList', data).then(res => { return res.data })
}

export const getVichelsList = (data) => {
  return OwnerGetRequest('getVichelsList', data).then(res => { return res.data })
}

export const getActiveSupplierList = (data) => {
  return OwnerPostRequest('getActiveSupplierList', data).then(res => { return res.data })
}

export const getReceivableReportAction = (data) => {
  return OwnerPostRequest('getReceivableReport', data).then(res => { return res.data })
}


export const checkProfitAndLoss = (data) => {
  return OwnerPostRequest('checkProfitAndLoss', data).then(res => { return res.data })
}

export const balanceSheetAction = (data) => {
  return OwnerPostRequest('balanceSheet', data).then(res => { return res.data })
}

export const getExpenseStatics = (data) => {
  return OwnerPostRequest('getExpenseStatics', data).then(res => { return res.data })
}

export const getIncomeStatics = (data) => {
  return OwnerPostRequest('getIncomeStatics', data).then(res => { return res.data })
}

export const profitAndLossStatics = (data) => {
  return OwnerPostRequest('profitAndLossStatics', data).then(res => { return res.data })
}

export const vehicleProfitAndLossStatics = (data) => {
  return OwnerPostRequest('vehicleProfitAndLossStatics', data).then(res => { return res.data })
}



//--------------------------|| INVOICE ||--------------------------------

export const getInvoiceList = (data) => {
  return OwnerPostRequest('getInvoiceList', data).then(res => { return res.data })
}

export const addInvoice = (data) => {
  return OwnerPostRequest('addInvoice', data).then(res => { return res.data })
}

export const editInvoice = (data) => {
  return OwnerPostRequest('editInvoice', data).then(res => { return res.data })
}

export const getInvoiceDetailById = (data) => {
  return OwnerPostRequest('getInvoiceDetailById', data).then(res => { return res.data })
}

export const deleteInvoiceById = (data) => {
  return OwnerPostRequest('deleteInvoice', data).then(res => { return res.data })
}


export const getBankDepositList = (data) => {
  return OwnerPostRequest('getBankDepositList', data).then(res => { return res.data })
}

export const deleteBankDeposit = (data) => {
  return OwnerPostRequest('deleteBankDeposit', data).then(res => { return res.data })
}


export const addBankDeposit = (data) => {
  return propertyOwnerRequestForm('addBankDeposit', data).then(res => { return res.data })
}

export const addBankAccountDetails = (data) => {
  return OwnerPostRequest('addBankAccountDetails', data).then(res => { return res.data })
}

export const getBankAccountList = (data) => {
  return OwnerPostRequest('getBankAccountList', data).then(res => { return res.data })
}

export const getAccountTypeList = (data) => {
  return OwnerGetRequest('getAccountTypeList', data).then(res => { return res.data })
}


export const getChequeList = (data) => {
  return OwnerPostRequest('getChequeList', data).then(res => { return res.data })
}

export const addChequeDetails = (data) => {
  return propertyOwnerRequestForm('addChequeDetails', data).then(res => { return res.data })
}

export const deleteCheque = (data) => {
  return OwnerPostRequest('deleteCheque', data).then(res => { return res.data })
}

export const getChequeCategoryList = (data) => {
  return OwnerGetRequest('getChequeCategoryList', data).then(res => { return res.data })
}

export const getBillSerialNo = (data) => {
  return OwnerGetRequest('getBillSerialNo', data).then(res => { return res.data })
}

export const addEstimate = (data) => {
  return propertyOwnerRequestForm('addEstimate', data).then(res => { return res.data })
}

export const getEstimateList = (data) => {
  return OwnerPostRequest('getEstimateList', data).then(res => { return res.data })
}

export const deleteEstimate = (data) => {
  return OwnerPostRequest('deleteEstimate', data).then(res => { return res.data })
}

//--------------------------|| JOURNAL ENTRY ||--------------------------------

export const getJournalEntryList = (data) => {
  return OwnerPostRequest('getJournalEntryList', data).then(res => { return res.data })
}

export const addJournalEntry = (data) => {
  return OwnerPostRequest('addJournalEntry', data).then(res => { return res.data })
}

export const getJournalSerialNo = (data) => {
  return OwnerPostRequest('getJournalSerialNo', data).then(res => { return res.data })
}

export const getBankAccountListById = (data) => {
  return OwnerPostRequest('getBankAccountListById', data).then(res => { return res.data })
}

export const getProductAndServices = (data) => {
  return OwnerPostRequest('getProductAndServices', data).then(res => { return res.data })
}


export const addProductAndServices = (data) => {
  return OwnerPostRequest('addProductAndServices', data).then(res => { return res.data })
}

export const updateProductAndServices = (data) => {
  return OwnerPostRequest('updateProductAndServices', data).then(res => { return res.data })
}

export const addBillPayment = (data) => {
  return OwnerPostRequest('addBillPayment', data).then(res => { return res.data })
}

export const getBillPaymentList = (data) => {
  return OwnerPostRequest('getBillPaymentList', data).then(res => { return res.data })
}

export const getBankCashAccountListById = (data) => {
  return OwnerPostRequest('getBankCashAccountListById', data).then(res => { return res.data })
}

export const getTotalAmountPaid = (data) => {
  return OwnerPostRequest('getTotalAmountPaid', data).then(res => { return res.data })
}

export const getOverdueExpenseList = (data) => {
  return OwnerPostRequest('getOverdueExpenseList', data).then(res => { return res.data })
}