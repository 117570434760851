const config = {
  baseUrl: "/",
  // baseUrl : "/property-owners-guyana/",
  //    serverPath: "http://localhost:8000/api/",
  // imageUrl: "http://localhost:8000/api/propertyOwner/uploads/",
  // Tech Server
  // serverPath: "https://espsofttech.in:6607/api/",
  // imageUrl: "https://espsofttech.in:6607/api/propertyOwner/uploads/",
  // Live Server
  // serverPath: "https://propertynu.com/api/authadmin",
  // propertyOwnerPath: "https://propertynu.com/api/propertyOwner",
  // userPath: "https://propertynu.com/api/auth",
  // imageUrl: "https://propertynu.com/api/propertyOwner/uploads/",
  // client server
  
serverPath: "https://dev.propertynu.com/api/",
  imageUrl: "https://dev.propertynu.com/media/",
  // REACT_APP_FB_APP_ID: "1364246204459038",
  REACT_APP_FB_APP_ID: "1364246204459038",
  GOOGLE_CLIENT_ID :"316641084538-7u4rrb19nu62ecgqafasrflm8n4jk59q.apps.googleusercontent.com",
  CLIENT_ID:
    process.env.CLIENT_ID ||
    // "ATrenVeG5tqfsAD8CrSTZfqo0T7ID1_IbdeyNLbNZlJMITmfFW5QSL9aBJEMGhORdcGlbIvsHCzMZds3",//test
       "ATmRXQlro43qGwjkNCidcv5s-4eLKDos5INqh5D-AL3WdaGphHKylDVaxAD-bDmU53D76iEXcaLtSUcu",
  APP_SECRET:
    process.env.APP_SECRET ||
    // "pk_test_51IpwoFSExUydZ4cTW4Bdd2h5Ur1YIfvs1C9B6mW3Ueis507hBu7TFyM8oO1tOruK8uJvaLsHWBWfIwAVrt0tiwtM00p2vFZnz7",test
        "EB8__1pwppIQZ3orL0_6mhVyKM1brDv8GBDbnQbvuGyhtlWybl8BOExKivZPIVacdOHZlwBQLbd_3e8t",
  stripePrivateKey:
    // "pk_test_51IpwoFSExUydZ4cTW4Bdd2h5Ur1YIfvs1C9B6mW3Ueis507hBu7TFyM8oO1tOruK8uJvaLsHWBWfIwAVrt0tiwtM00p2vFZnz7",//test
       "pk_live_51NuHzfDFjJuaiCERqYq9QT10vVmPNXqmnP5eLSwGMkiaDye2f5efzaqwMxy8UsuTnxxX0xiYfBSIt1bYlPOTEXnB00YxAeTCHo",
  // googleMapsApiKey: "AIzaSyA77sBjIxxLGde20OWRNhjzJL17vMbt1YM"
  googleMapsApiKey: "AIzaSyATxU4112etWoagzrKUILzSLFBH37H5C1k"

};
export default config;
