import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Button, Form, Modal, Dropdown, Table } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link, useParams } from "react-router-dom";
import Header from "../../../commanComponents/usernavbar"
import config from "../../../../config";
import "../../css/staffdashboard.css"
import "../../css/finance.css"
import { getBillList } from '../../../../Action/propertyOwner.action'
import { toast, Toaster } from "react-hot-toast";
import ListMenuButton from "./listMenu";
import UserNameAndTab from "./UserNameAndTab";


const BillList = () => {
    const { id } = useParams()


    useEffect(() => {
        // Add the class to the body tag
        document.body.classList.add('staffbody');

        // Cleanup function to remove the class when the component is unmounted
        return () => {
            document.body.classList.remove('staffbody');
        };
    }, []);


    createTheme('solarized', {
        text: {
            primary: '#000',
            secondary: '#2aa198',
        },
        background: {
            default: '',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#EBEBEB',
        },
        action: {
            button: '#E46422',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const customStyles = {
        rows: {
            style: {
                minHeight: '70px', // override the row height

            },
        },
        headCells: {

            style: {
                minHeight: '60px',
                paddingLeft: '20px', // override the cell padding for head cells
                paddingRight: '20px',
                background: '#FFF8F4',

            },
        },
        cells: {
            style: {
                paddingLeft: '20px', // override the cell padding for data cells
                paddingRight: '20px',
            },
        },
    };



    const [bill, setBill] = useState([]);
    const [allBillList, setAllBillList] = useState([]);
    const [filterText, setFilterText] = useState("");

    console.log(filterText, filterText)


    useEffect(() => {
        fetchBillList();
    }, []);

    useEffect(() => {
        let filteredData = allBillList;
        if (filterText) {
            filteredData = filteredData.filter(item => {
                const lowerCaseFilterText = filterText.toLowerCase();

                return (
                    item.supplierName.toLowerCase().includes(lowerCaseFilterText) ||
                    item.name.toLowerCase().includes(lowerCaseFilterText) ||
                    item.ownerName.toLowerCase().includes(lowerCaseFilterText)
                );
            });
        }

        setBill(filteredData)
    }, [filterText]);

    const fetchBillList = async () => {
        let res = await getBillList({ type: 1, supplierId : id });
        if (res.success) {
            setBill(res.data || []);
            setAllBillList(res.data || [])
        }
    }

    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "8%"
        },
        {
            name: 'Supplier Name',
            selector: row => row.supplierName,
            width: "16%",
        },
        {
            name: 'Mailing Address',
            selector: row => row.mailingAddress,
            width: "16%",
        },
        {
            name: 'Terms',
            selector: row => row.terms,
            width: "16.5%",
        },
        {
            name: 'Bill Date',
            width: "12.5%",
            selector: row => {
                const date = new Date(row.dueDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            },
        },
        {
            name: 'Due Date',
            width: "12.5%",
            selector: row => {
                const date = new Date(row.dueDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            },
        },
        
        {
            name: 'Bill Number',
            selector: row => row.billNo,
            width: "15.5%",
        },
    ];

    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <Toaster />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <UserNameAndTab />

                    <div className="listMenuButton">
                        <Col xl={12} lg={12} className="mb-3">
                            <Card className="border-0 tablistfinance">
                                <Card.Body>
                                    <ListMenuButton />
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                    <div className="">
                        <Col lg={12} className="mb-3">
                            <Card className="border-0 bg-white p-3">
                                <Card.Header className="border-0 mb-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className=""><h4 className="mb-0 fw-bolder">Bill </h4></div>
                                        

                                    </div>
                                    <Row>

                                        <Col lg={12} className="text-end pull-right">


                                        <Link to={`${config.baseurl}addbill`}>
                                            <Button
                                                variant="primary"
                                                className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn text-white"
                                                //onClick={setIsModelOpen}

                                            >
                                                Create Bill
                                            </Button>
                                            </Link>

                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group
                                                className="me-2"
                                                controlId="formBasicPassword"
                                            >

                                                <Row className="mt-2">

                                                    <Col lg={3} className="mb-4">
                                                        <div className="position-relative">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Search..."
                                                                value={filterText}
                                                                onChange={(e) => setFilterText(e.target.value)}
                                                            />
                                                            <span className="text-light-grey inner-field small"></span>
                                                        </div>
                                                    </Col>



                                                    <Col lg={3}>

                                                    </Col>
                                                    <Col lg={12}>
                                                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Card.Header>
                                <Card.Body className="p-0">
                                    <div className="">
                                        {bill.length > 0 ?
                                            <Row className="">
                                                <Col lg={12} xs={12}>
                                                    <div className="mb-4">
                                                        <DataTable
                                                            columns={columns}
                                                            data={bill}
                                                            customStyles={customStyles}
                                                            theme="solarized"

                                                        />
                                                       
                                                    </div>
                                                </Col>

                                            </Row>
                                            :
                                            <div className="text-center">
                                                <div>
                                                    <img src="images/nodatafound.svg" />
                                                </div>
                                                <h5 className="mb-1">Bill Report</h5>
                                                <div>This report doesn’t have any data</div>
                                            </div>
                                        }

                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>

                </Container>
            </section>
        </div >
    );
};

export default BillList;
