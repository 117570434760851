import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Button, Form, Modal, Dropdown, Table } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from "react-router-dom";
import config from "../../../../config";
import Header from "../../../commanComponents/usernavbar"
import "../../css/staffdashboard.css"
import "../../css/finance.css"
import { getProductAndServices, updateProductAndServices, addProductAndServices, getBankAccountListById } from '../../../../Action/propertyOwner.action'
import { GoPencil } from "react-icons/go";
import { toast, Toaster } from "react-hot-toast";
import ListMenuButton from "./listMenu";
import UserNameAndTab from "./UserNameAndTab";


const ProductAndService = () => {

	useEffect(() => {
		// Add the class to the body tag
		document.body.classList.add('staffbody');

		// Cleanup function to remove the class when the component is unmounted
		return () => {
			document.body.classList.remove('staffbody');
		};
	}, []);


	createTheme('solarized', {
		text: {
			primary: '#000',
			secondary: '#2aa198',
		},
		background: {
			default: '',
		},
		context: {
			background: '#cb4b16',
			text: '#FFFFFF',
		},
		divider: {
			default: '#EBEBEB',
		},
		action: {
			button: '#E46422',
			hover: 'rgba(0,0,0,.08)',
			disabled: 'rgba(0,0,0,.12)',
		},
	}, 'dark');

	const customStyles = {
		rows: {
			style: {
				minHeight: '70px', // override the row height

			},
		},
		headCells: {

			style: {
				minHeight: '60px',
				paddingLeft: '20px', // override the cell padding for head cells
				paddingRight: '20px',
				background: '#FFF8F4',

			},
		},
		cells: {
			style: {
				paddingLeft: '20px', // override the cell padding for data cells
				paddingRight: '20px',
			},
		},
	};


	const [expenseList, setExpenseList] = useState([]);
	const [allexpenseList, setAllExpenseList] = useState([]);
	const [isModelOpen, setIsModelOpen] = useState(false);
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [incomeAccoutList, setIncomeAccountList] = useState([])
	const [expenseAccoutList, setExpenseAccountList] = useState([])



	useEffect(() => {
		fetchExpenseList();
		fetchIncomeAccountList()
		fetchExpenseAccountList()
	}, []);

	const fetchExpenseList = async () => {
		let res = await getProductAndServices({ type: 1 });
		if (res.success) {
			setExpenseList(res.data || []);
			setAllExpenseList(res.data || [])
		}
	}

	const fetchExpenseAccountList = async () => {
		try {
			let res = await getBankAccountListById({ accountType: 2 })
			if (res.success) {
				setExpenseAccountList(res.data || [])
			}
		} catch (error) {

		}
	}

	const fetchIncomeAccountList = async () => {
		try {
			let res = await getBankAccountListById({ accountType: 1 })
			if (res.success) {
				setIncomeAccountList(res.data || [])
			}
		} catch (error) {

		}
	}


	const modelClose = () => {
		setIsModelOpen(false);
		setIsEdit(false)
		setFormData({})
		setErrors({})
	}


	const inputHandler = (e) => {
		const { name, value, type, checked } = e.target;
		const inputValue = type === "checkbox" ? (checked ? 0 : 1) : value;
	  
		setFormData((old) => ({ ...old, [name]: inputValue }));
	  
		if (typeof inputValue === "string" && inputValue.trim() !== "") {
		  setErrors((oldErrors) => ({ ...oldErrors, [name]: "" }));
		}
	  };
	  

	const validateForm = () => {
		const newErrors = {};
		if (!formData?.category) newErrors.category = "Select category name.";
		if (!formData?.incomeAccountId) newErrors.incomeAccountId = "Please select name.";
		if (formData.category == 1) {
			if (!formData?.expenseAccountId) newErrors.expenseAccountId = "Please select name.";
		}
		if (!formData?.name) newErrors.name = "Name is required.";
		//if (!formData?.salesRateAndPrice) newErrors.salesRateAndPrice = "Sales rate and price is required.";
		//if (!formData?.quantity) newErrors.quantity = "Quantity is required.";
		//if (!formData?.asOf) newErrors.asOf = "As of is required.";
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const columns = [
		{
			name: "#",
			selector: (row, index) => index + 1,
			sortable: true,
			// width: "9%"
		},
		{
			name: 'Product and Service Category',
			selector: row => {
				switch (row.category) {
					case 1:
						return 'Inventory';
					case 2:
						return 'Non Inventory';
					case 3:
						return 'Services';
					default:
						return 'Unknown';
				}
			},
		},
		{
			name: 'Income Account',
			selector: row => row.incomeAccountName,
			// width: "9%",
		},
		{
			name: 'Expense Account',
			selector: row => row.expenseAccountName,
			// width: "9%",
		},
		{
			name: 'name',
			selector: row => '$' + row.name,
			// width: "9%",
			sortable: true,
		},
		{
			name: 'Sales Rate/Price',
			selector: row =>  row.salesRateAndPrice ? '$' +row.salesRateAndPrice :'$' + 0,
			// width: "9%",
			sortable: true,
		},
		{
			name: 'As of',
			// width: "9%",
			selector: row => {
				const date = new Date(row.asOf);
				const day = String(date.getDate()).padStart(2, '0');
				const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
				const year = date.getFullYear();
				return `${day}/${month}/${year}`;
			},
		},
		{
			name: 'Action',
			width: "18.5%",
			selector: row => (
				<>
					<Button variant={`primary`}
						className="btn-sm h-auto  rounded-5 " onClick={(e) => handleEdit(e, row)}>
						<GoPencil />
						&nbsp;Edit
					</Button>
					&nbsp;
					<div className="d-inline-block">
					</div>
				</>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},

	];


	const handleSubmit = async () => {
		if (!validateForm()) return;
		try {
			setIsLoading(true)
			let data = {
				...formData,
				type: 1
			};

			console.log(data)

			if (isEdit) {
				let res = await updateProductAndServices(data);
				if (res.success) {
					toast.success(res.msg);
					fetchExpenseList();
					modelClose();
					setFormData({})
				} else {
					toast.error(res.msg);
				}
				setIsLoading(false)
			} else {
				let res = await addProductAndServices(data);
				if (res.success) {
					toast.success(res.msg);
					fetchExpenseList();
					modelClose();
					setFormData({})
				} else {
					toast.error(res.msg);
				}
				setIsLoading(false)
			}
		} catch (error) {
			toast.error('Something went wrong please try again after some time.');
		}
	};

	const handleEdit = (e, expense) => {
		e.preventDefault()
		console.log('expense', expense)
		setFormData(expense);
		setIsEdit(true);
		setIsModelOpen(true);
	};


	return (
		<div className="staffdashboard bg-pink">
			<Header />
			<Toaster />
			<section className="listing pt-5 pb-5 overflow-hidden vh-80">
				<Container>
					<UserNameAndTab />
					<div className="listMenuButton">
						<Col xl={12} lg={12} className="mb-3">
							<Card className="border-0 tablistfinance">
								<Card.Body>
									<ListMenuButton />
								</Card.Body>
							</Card>
						</Col>
					</div>
					<div className="">
						<Col lg={12} className="mb-3">
							<Card className="border-0 bg-white p-3">
								<Card.Header className="border-0 mb-0 pb-0">
									<div className="d-flex align-items-center justify-content-between mb-4">
										<div className=""><h4 className="mb-0 fw-bolder">Product And Services </h4></div>
									</div>
									<Row>
										<Col lg={12} className="text-end pull-right">
											<Button
												variant="primary"
												className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn text-white"
												onClick={setIsModelOpen}
											>
												Add
											</Button>
										</Col>
									</Row>

								</Card.Header>
								<Card.Body className="p-0">
									<div className="">
										{expenseList.length > 0 ?
											<Row className="">
												<Col lg={12} xs={12}>
													<div className="mb-4">
														<DataTable
															columns={columns}
															data={expenseList}
															customStyles={customStyles}
															theme="solarized"

														/>

													</div>
												</Col>

											</Row>
											:
											<div className="text-center">
												<div>
													<img src="images/nodatafound.svg" />
												</div>
												<h5 className="mb-1">Product and Services Report</h5>
												<div>This report doesn’t have any data</div>
											</div>
										}

									</div>
								</Card.Body>
							</Card>
						</Col>
					</div>

					<Modal
						show={isModelOpen}
						onHide={() => modelClose(false)}
						dialogClassName="modal-90w modal-xl"
						aria-labelledby="example-custom-modal-styling-title"
					>
						<Modal.Header closeButton>
							<Modal.Title>
								<b>{isEdit ? 'Edit Product and Service ' : 'Add Product and Service '}</b>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="">
								<Col lg={12} className="mb-3">
									<Card className="border-0 bg-white p-3">

										<Card.Body className="p-0 mb-3">
											<div className="">
												<Row className="mt-2">

													<Col lg={6} className="mb-4">
														<Form.Group>
															<Form.Label className="text-light-grey mb-1">Product and Service Category</Form.Label>
															<Form.Select
																name="category"
																value={formData.category}
																onChange={inputHandler}
																isInvalid={!!errors.category}
															>
																<option value="default">Select Account Type</option>
																<option value="1">Inventory</option>
																<option value="2">Non Inventory</option>
																<option value="3">Services</option>

															</Form.Select>
															<Form.Control.Feedback type="invalid">{errors.category}</Form.Control.Feedback>
														</Form.Group>
													</Col>


													<Col lg={6} className="mb-4">
														<Form.Group controlId="formBasicExpense">
															<Form.Label className="text-light-grey mb-1">Income Account</Form.Label>
															<Form.Control
																as="select"
																name="incomeAccountId"
																value={formData?.incomeAccountId}
																onChange={inputHandler}
															>
																{incomeAccoutList.length > 0 ? <option value="">Select Account</option> : <option value="">No account found</option>}


																{incomeAccoutList.map(item => (
																	<option key={item.id} value={item.id}>
																		{item.accountName}
																	</option>
																))}
															</Form.Control>
															{errors.incomeAccountId && <small className="text-danger">{errors.incomeAccountId}</small>}
														</Form.Group>
													</Col>
													{formData.category == 1 &&
														<Col lg={6} className="mb-4">
															<Form.Group controlId="formBasicExpense">
																<Form.Label className="text-light-grey mb-1">Expense Account</Form.Label>
																<Form.Control
																	as="select"
																	name="expenseAccountId"
																	value={formData?.expenseAccountId}
																	onChange={inputHandler}
																>
																	{expenseAccoutList.length > 0 ? <option value="">Select Account</option> : <option value="">No account found</option>}


																	{expenseAccoutList.map(item => (
																		<option key={item.id} value={item.id}>
																			{item.accountName}
																		</option>
																	))}
																</Form.Control>
																{errors.expenseAccountId && <small className="text-danger">{errors.expenseAccountId}</small>}
															</Form.Group>
														</Col>
													}

													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Name</Form.Label>
															<Form.Control type="text" name="name" onChange={inputHandler} value={formData?.name || ''} />
															{errors.name && <small className="text-danger">{errors.name}</small>}
														</Form.Group>
													</Col>

													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Cost</Form.Label>
															<Form.Control type="text" name="cost" onChange={inputHandler} value={formData?.cost || ''} />
														</Form.Group>
													</Col>

													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Sales Rate/Price</Form.Label>
															<Form.Control type="number" name="salesRateAndPrice" onChange={inputHandler} value={formData?.salesRateAndPrice || ''} />
															{/* {errors.salesRateAndPrice && <small className="text-danger">{errors.salesRateAndPrice}</small>} */}
														</Form.Group>
													</Col>

													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">As of </Form.Label>
															<Form.Control type="date" name="asOf" onChange={inputHandler} value={formData?.asOf || ''} />
															{/* {errors.asOf && <small className="text-danger">{errors.asOf}</small>} */}
														</Form.Group>
													</Col>


													<Col lg={6} className="mb-4">
														<Form.Group className="" controlId="formBasicEmail">
															<Form.Label className="text-light-grey mb-1">Initial Quantity on Hand</Form.Label>
															<Form.Control type="text" name="quantity" onChange={inputHandler} value={formData?.quantity || ''} />
															{/* {errors.quantity && <small className="text-danger">{errors.quantity}</small>} */}
														</Form.Group>
													</Col>

													<Col lg={6} className="mb-4">
														<Form.Group>
															<Form.Check
																type="checkbox"
																id="isActive"
																name="isActive"
																label="Make this account inactive"
																checked={formData.isActive === 0} // Checked if isActive is 0 (inactive)
																onChange={(e) => inputHandler({ target: { name: 'isActive', value: e.target.checked ? 0 : 1 } })}
																isInvalid={!!errors.isActive}
															/>
														</Form.Group>
													</Col>


												</Row>
											</div>

										</Card.Body>

										<Col lg={12} className="text-center">
											<div>
												<Link to={`#`}>
													{isLoading ?
														<button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" disabled>
															Processing...
														</button>
														:
														<button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" onClick={handleSubmit}>
															{isEdit ? 'Update Product & Services' : 'Save Product & Services'}
														</button>
													}
												</Link>
											</div>
										</Col>
									</Card>
								</Col>
							</div>
						</Modal.Body>
					</Modal>


				</Container>
			</section>
		</div >
	);
};

export default ProductAndService;
