import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Button, Form, Modal, Dropdown, Table } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link, useParams } from "react-router-dom";
import config from "../../../../config";
import Header from "../../../commanComponents/usernavbar"
import "../../css/staffdashboard.css"
import "../../css/finance.css"
import { getBillList, updatebill, addNewBill } from '../../../../Action/propertyOwner.action'
import { GoPencil } from "react-icons/go";
import { toast, Toaster } from "react-hot-toast";
import { useReactToPrint } from 'react-to-print';

import ListMenuButton from "./listMenu";
import UserNameAndTab from "./UserNameAndTab";




const CreateBill = () => {
    const { id } = useParams()
 

    useEffect(() => {
        // Add the class to the body tag
        document.body.classList.add('staffbody');

        // Cleanup function to remove the class when the component is unmounted
        return () => {
            document.body.classList.remove('staffbody');
        };
    }, []);


    createTheme('solarized', {
        text: {
            primary: '#000',
            secondary: '#2aa198',
        },
        background: {
            default: '',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#EBEBEB',
        },
        action: {
            button: '#E46422',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const customStyles = {
        rows: {
            style: {
                minHeight: '70px', // override the row height

            },
        },
        headCells: {

            style: {
                minHeight: '60px',
                paddingLeft: '20px', // override the cell padding for head cells
                paddingRight: '20px',
                background: '#FFF8F4',

            },
        },
        cells: {
            style: {
                paddingLeft: '20px', // override the cell padding for data cells
                paddingRight: '20px',
            },
        },
    };



    const [bill, setBill] = useState([]);
    const [allBillList, setAllBillList] = useState([]);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filterText, setFilterText] = useState("");

    console.log(filterText, filterText)


    useEffect(() => {
        fetchBillList();
    }, []);

    useEffect(() => {
        let filteredData = allBillList;
        if (filterText) {
            filteredData = filteredData.filter(item => {
                const lowerCaseFilterText = filterText.toLowerCase();

                return (
                    item.supplierName.toLowerCase().includes(lowerCaseFilterText) ||
                    item.name.toLowerCase().includes(lowerCaseFilterText) ||
                    item.ownerName.toLowerCase().includes(lowerCaseFilterText)
                );
            });
        }

        setBill(filteredData)
    }, [filterText]);

    const fetchBillList = async () => {
        let res = await getBillList({ type: 1, supplierId : id });
        if (res.success) {
            setBill(res.data || []);
            setAllBillList(res.data || [])
        }
    }

    const modelClose = () => {
        setIsModelOpen(false);
        setIsEdit(false)
        setFormData({})
        setErrors({})
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((old) => {
            return { ...old, [name]: value };
        });
        if (value.trim() !== "") {
            setErrors((oldErrors) => ({ ...oldErrors, [name]: "" }));
        }
    };


    const validateForm = () => {
        const newErrors = {};
        if (!formData?.email) newErrors.email = "Email is required.";
        if (!formData?.terms) newErrors.terms = "Terms is required.";
        if (!formData?.billDate) newErrors.billDate = "Bill date is required.";
        if (!formData?.dueDate) newErrors.dueDate = "Due date is required.";
        if (!formData?.category) newErrors.category = "Category is required.";
        if (!formData?.amount) newErrors.amount = "Amount is required.";
        if (!formData?.description) newErrors.description = "Description is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "8%"
        },
        {
            name: 'Email',
            selector: row => row.email,
            width: "8.5%",
        },
        {
            name: 'Terms',
            selector: row => row.terms,
            width: "15.5%",
        },
        {
            name: 'Bill Date',
            width: "12.5%",
            selector: row => {
                const date = new Date(row.dueDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            },
        },
        {
            name: 'Due Date',
            width: "12.5%",
            selector: row => {
                const date = new Date(row.dueDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            },
        },
        {
            name: 'Category',
            selector: row => row.category,
            width: "8.5%",
        },
        {
            name: 'amount',
            selector: row => '$'+row.amount,
            width: "15.5%",
        },
        {
            name: 'Action',
            selector: row => (
                <>
                    <Button variant={`primary`}
                        className="btn-sm h-auto  rounded-5 " onClick={(e) => handleEdit(e, row)}>
                        <GoPencil />
                        &nbsp;Edit
                    </Button>

                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];



    const handleSubmit = async () => {
        if (!validateForm()) return;
        try {
            setIsLoading(true)
            let data = {
                ...formData,
                type: 1,
                supplierId: id
            };
            if (isEdit) {
                let res = await updatebill(data);
                if (res.success) {
                    toast.success(res.msg);
                    fetchBillList();
                    modelClose();
                    setFormData({})
                } else {
                    toast.error(res.msg);
                }
                setIsLoading(false)
            } else {
                let res = await addNewBill(data);
                if (res.success) {
                    toast.success(res.msg);
                    fetchBillList();
                    modelClose();
                    setFormData({})
                } else {
                    toast.error(res.msg);
                }
                setIsLoading(false)
            }
        } catch (error) {
            toast.error('Something went wrong please try again after some time.');
        }
    };

    const handleEdit = (e, expense) => {
        e.preventDefault()
        console.log('expense', expense)
        setFormData(expense);
        setIsEdit(true);
        setIsModelOpen(true);
    };

    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <Toaster />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <UserNameAndTab />

                    <div className="listMenuButton">
                        <Col xl={12} lg={12} className="mb-3">
                            <Card className="border-0 tablistfinance">
                                <Card.Body>
                                    <ListMenuButton />
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                    <div className="">
                        <Col lg={12} className="mb-3">
                            <Card className="border-0 bg-white p-3">
                                <Card.Header className="border-0 mb-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className=""><h4 className="mb-0 fw-bolder">Bill </h4></div>
                                        

                                    </div>
                                    <Row>

                                        <Col lg={12} className="text-end pull-right">


                                            <Button
                                                variant="primary"
                                                className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn text-white"
                                                onClick={setIsModelOpen}
                                            >
                                                Create New Bill
                                            </Button>

                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group
                                                className="me-2"
                                                controlId="formBasicPassword"
                                            >

                                                <Row className="mt-2">

                                                    <Col lg={3} className="mb-4">
                                                        <div className="position-relative">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Search..."
                                                                value={filterText}
                                                                onChange={(e) => setFilterText(e.target.value)}
                                                            />
                                                            <span className="text-light-grey inner-field small"></span>
                                                        </div>
                                                    </Col>



                                                    <Col lg={3}>

                                                    </Col>
                                                    <Col lg={12}>
                                                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Card.Header>
                                <Card.Body className="p-0">
                                    <div className="">
                                        {bill.length > 0 ?
                                            <Row className="">
                                                <Col lg={12} xs={12}>
                                                    <div className="mb-4">
                                                        <DataTable
                                                            columns={columns}
                                                            data={bill}
                                                            customStyles={customStyles}
                                                            theme="solarized"

                                                        />
                                                       
                                                    </div>
                                                </Col>

                                            </Row>
                                            :
                                            <div className="text-center">
                                                <div>
                                                    <img src="images/nodatafound.svg" />
                                                </div>
                                                <h5 className="mb-1">Bill Report</h5>
                                                <div>This report doesn’t have any data</div>
                                            </div>
                                        }

                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>

                    <Modal
                        show={isModelOpen}
                        onHide={() => modelClose(false)}
                        dialogClassName="modal-90w modal-xl"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <b>{isEdit ? 'Edit Bill' : 'Create Bill'}</b>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>

                                <Form.Group controlId="formMailingAddress">
                                    <Form.Label>Mailing Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <small className="text-danger">{errors.email}</small>}

                                </Form.Group>

                                <Form.Group controlId="formTerms">
                                    <Form.Label>Terms</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="terms"
                                        value={formData.terms}
                                        onChange={handleChange}
                                    />
                                    {errors.terms && <small className="text-danger">{errors.terms}</small>}

                                </Form.Group>

                                <Form.Group controlId="formBillDate">
                                    <Form.Label>Bill Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="billDate"
                                        value={formData.billDate}
                                        onChange={handleChange}
                                    />
                                    {errors.billDate && <small className="text-danger">{errors.billDate}</small>}

                                </Form.Group>

                                <Form.Group controlId="formDueDate">
                                    <Form.Label>Due Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="dueDate"
                                        value={formData.dueDate}
                                        onChange={handleChange}
                                    />
                                    {errors.dueDate && <small className="text-danger">{errors.dueDate}</small>}

                                </Form.Group>

                                <Form.Group controlId="formCategory">
                                    <Form.Label>Category</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="category"
                                        value={formData.category}
                                        onChange={handleChange}
                                    />
                                    {errors.category && <small className="text-danger">{errors.category}</small>}

                                </Form.Group>

                                <Form.Group controlId="formDescription">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                    />
                                    {errors.description && <small className="text-danger">{errors.description}</small>}

                                </Form.Group>
                                
                                <Form.Group controlId="formAmount">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="amount"
                                        value={formData.amount}
                                        onChange={handleChange}
                                    />
                                    {errors.amount && <small className="text-danger">{errors.amount}</small>}
                                </Form.Group>
                            </Form>
                            
                        </Modal.Body>
                        <Col lg={12} className="text-center">
                                <div>
                                    <Link to={`#`}>
                                        {isLoading ?
                                            <button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" disabled>
                                                Processing...
                                            </button>
                                            :
                                            <button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" onClick={handleSubmit}>
                                                {isEdit ? 'Update Bill' : 'Save Bill'}
                                            </button>
                                        }
                                    </Link>
                                </div>
                            </Col>
                    </Modal>


                </Container>
            </section>
        </div >
    );
};

export default CreateBill;
