import React, { useState, useEffect, useMemo } from "react";
import Header from "../commanComponents/userheader";
import Footer from "../commanComponents/userfooter";
import config from "../../coreFIles/config";
import { toast, Toaster } from "react-hot-toast";
import {
  getPropertyDetailAction,
  insertBookingDetailsAction,
  getFygaroJwt
} from "../../Action/user.action";
import { paymentIntentAction } from "../../Action/propertyOwner.action";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { Buffer } from "buffer";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../generalPages/stripeForm";
import moment from "moment";
const stripePromise = loadStripe(config.stripePrivateKey);
const Payment = () => {
  const navigate = useNavigate();
  const {
    id,
    numberOfAdults,
    checkin,
    checkout,
    numberOfChildren,
    numberOfInfants,
    numberOfPets,
    roomType,
  } = useParams();
  const [leaseCount, setleaseCount] = useState({
    years: 0,
    months: 1,
  });
  const [propertyDetail, setpropertyDetail] = useState({
    PropertyName: "",
    amenityArr: [],
    bikeParking: "",
    carParking: "",
    categoryName: "",
    checkIn: "",
    checkOut: "",
    datetime: "",
    description: "",
    documentArr: [],
    email: "",
    guestCount: "",
    id: "",
    imageArr: [],
    isDeleted: "",
    location: "",
    numberOfBathroom: "",
    numberOfBedrooms: "",
    numberOfBeds: "",
    petsAllowed: "",
    price: "",
    propertyName: "",
    propertyPurchaseType: "",
    propertyPurchaseTypeId: "",
    status: "",
    terms: "",
  });
  const [imagesArr, setimagesArr] = useState([]);
  const [amenityArr, setAmenityArr] = useState([]);
  const [fygaroToken, setFygaroToken] = useState('');
  const [totalBookingDay, settotalBookingDay] = useState(0);
  const [paymentType, setpaymentType] = useState(1); //1=Card ,2=Bank Transfer ,3=Paypal,4=Stripe,5=MMG
  const [clientSecret, setClientSecret] = useState("");
  const [chooseplanDetails, setChoosePlanDetails] = useState({
    description: "",
  });
  const [totalAmount, settotalAmount] = useState(0);
  const [orderAmount, setorderAmount] = useState(0);
  const [roomPrice, setRoomPrice] = useState(0);

  useEffect(() => {
    getListingDetails();
    getFygaroPayloadAPI()
  }, []);

  const getFygaroPayloadAPI = async () => {
    let fygaroPayload = {
      id,
      amount: 1
    }
    let jwtRecieved = await getFygaroJwt(fygaroPayload)
    if (jwtRecieved.success) {
      setFygaroToken(jwtRecieved.data)
    }
  }

  const getListingDetails = async () => {
    let res = await getPropertyDetailAction({ id: id, roomType: roomType });
    console.log("getPropertyDetailAction", res.data);
    let priceOfRoom = res.data?.price;
    if (res.success) {
      setpropertyDetail(res.data);

      let roomPriceRes = res.data.getPropertyRoomPrice;
      if (roomPriceRes.length > 0) {
        setRoomPrice(
          roomPriceRes[0]?.price ? roomPriceRes[0]?.price : res.data?.price
        );
        priceOfRoom = roomPriceRes[0]?.price
          ? roomPriceRes[0]?.price
          : res.data?.price;
      } else {
        setRoomPrice(res.data?.price);
      }

      setimagesArr(JSON.parse(res.data.imageArr));
      setAmenityArr(res.data.amenityArr);
    }
    if (checkin && checkout) {
      //Difference in number of days
      var date1 = new Date(checkin);
      var date2 = new Date(checkout);

      // To calculate the time difference of two dates
      var Difference_In_Time = date2.getTime() - date1.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      settotalBookingDay(Difference_In_Days);
      if (res.data?.propertyPurchaseTypeId == 2) {
        let totalRoomPrice =
          (parseFloat(numberOfAdults) / 2 - parseInt(numberOfAdults / 2) > 0
            ? parseInt(parseFloat(numberOfAdults) / 2) + 1
            : parseFloat(numberOfAdults) / 2) *
          parseFloat(priceOfRoom) *
          Difference_In_Days;
        const securityDeposit = parseFloat(res.data?.securityDeposit ?? 0);
        const cleaningAndMaintenanceFee = parseFloat(res?.data?.cleaningAndMaintenanceFee ?? 0);
        const taxPercentage = parseFloat(res.data?.taxPercentage ?? 0);
        settotalAmount(
          totalRoomPrice + securityDeposit + cleaningAndMaintenanceFee + (totalRoomPrice * taxPercentage) / 100

        );
      } else if (res.data?.propertyPurchaseTypeId == 1) {
        settotalAmount(parseFloat(priceOfRoom));
      } else {

        const cleaningAndMaintenanceFee = parseFloat(
          res?.data?.cleaningAndMaintenanceFee ?? 0
        );
        const securityDeposit = parseFloat(res.data?.securityDeposit ?? 0);
        const price = parseFloat(res.data?.price ?? 0);
        const taxPercentage = parseFloat(res.data?.taxPercentage ?? 0);
        const leasePeriod = parseInt(
          leaseCount[res.data.priceType ? "years" : "months"] ?? 0
        );
        const numberOfAdultsParsed = parseFloat(numberOfAdults ?? 0);

        const baseAmount = price * leasePeriod * numberOfAdultsParsed;
        const taxAmount = (baseAmount * taxPercentage) / 100;

        const totalAmount =
          cleaningAndMaintenanceFee + securityDeposit + baseAmount + taxAmount;

        settotalAmount(totalAmount);
      }
    }
  };

  const paymentIntent = async () => {
    let amount = totalAmount;

    let res = await paymentIntentAction({ amount: amount });
    if (res.success) {
      setClientSecret({
        clientSecret: Buffer.from(res.client_secret, "base64").toString(
          "ascii"
        ),
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>    PAYPAL PAYMENT  START   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const createOrder = (data, actions) => {
    let amount = totalAmount;
    return actions.order
      .create({
        purchase_units: [
          {
            description: chooseplanDetails.description,
            amount: {
              currency_code: "USD",
              value: amount,
            },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const onApprove = (data, actions) => {
    let amount = 0;
    let taxAmount = 0;
    let cleaningFee = 0;
    let petAmount = 0;
    if (propertyDetail?.propertyPurchaseTypeId == 2) {
      let taxAmountCalculate =
        ((parseFloat(numberOfAdults) / 2 - parseInt(numberOfAdults / 2) > 0
          ? parseInt(parseFloat(numberOfAdults) / 2) + 1
          : parseFloat(numberOfAdults) / 2) *
          parseFloat(roomPrice) *
          totalBookingDay *
          parseFloat(propertyDetail?.taxPercentage)) /
        100;

      let daysAmount =
        (parseFloat(numberOfAdults) / 2 - parseInt(numberOfAdults / 2) > 0
          ? parseInt(parseFloat(numberOfAdults) / 2) + 1
          : parseFloat(numberOfAdults) / 2) *
        parseFloat(roomPrice) *
        totalBookingDay;

      if (taxAmountCalculate) {
        taxAmount = taxAmountCalculate;
      }
      if (propertyDetail?.cleaningAndMaintenanceFee) {
        cleaningFee = propertyDetail?.cleaningAndMaintenanceFee;
      }
      let totalPetsAmount = parseFloat(
        propertyDetail?.petsPrice && numberOfPets > 0
          ? parseInt(numberOfPets) * parseFloat(propertyDetail?.petsPrice)
          : 0
      );
      console.log(totalPetsAmount, "000");
      if (totalPetsAmount) {
        petAmount = totalPetsAmount;
      }
      amount =
        parseFloat(daysAmount) +
        parseFloat(taxAmount) +
        parseFloat(cleaningFee) +
        parseInt(petAmount);
    } else if (propertyDetail?.propertyPurchaseTypeId == 3) {
      amount = parseFloat(roomPrice) * parseInt(parseFloat(numberOfAdults));
    } else {
      amount = propertyDetail?.price;
    }

    return actions.order.capture().then(async function (res) {
      if (res.status == "COMPLETED") {
        let arr = {
          propertyId: parseInt(id),
          payerId: res.payer.payer_id,
          orderId: res.id,
          receiptUrl: res.links[0].href,
          paymentMethodId: 1,
          numberOfAdults: parseInt(numberOfAdults),
          numberOfChildren: parseInt(numberOfChildren),
          noOfInfants: numberOfInfants,
          noOfPets: numberOfPets,
          propertyPurchaseTypeId: propertyDetail?.propertyPurchaseTypeId,
          checkInDate: checkin,
          checkOutDate: checkout,
          roomType: roomType,
          securityDeposit: propertyDetail?.securityDeposit,
          cardNumber: "",
          expiryDate: "",
          accountNumber: "",
          amount: amount,
          taxAmount: taxAmount,
          claningAmount: propertyDetail?.cleaningAndMaintenanceFee,
          ifscCode: "",
          upiId: "",
          paymentResponse: res,
          PetsAmountTotal: petAmount,
        };
        console.log(arr);
        let arr1 = {
          propertyId: parseInt(id),
          payerId: res.payer.payer_id,
          orderId: res.id,
          receiptUrl: res.links[0].href,
          paymentMethodId: 1,
          securityDeposit: propertyDetail?.securityDeposit,
          taxAmount: taxAmount,
          claningAmount: propertyDetail?.cleaningAndMaintenanceFee,
          numberOfMonths: parseInt(numberOfAdults),
          propertyPurchaseTypeId: propertyDetail?.propertyPurchaseTypeId,
          paymentResponse: res,
        };

        let arr3 = {
          propertyId: parseInt(id),
          payerId: res.payer.payer_id,
          orderId: res.id,
          receiptUrl: res.links[0].href,
          taxAmount: taxAmount,
          securityDeposit: 0,
          claningAmount: propertyDetail?.cleaningAndMaintenanceFee,
          paymentMethodId: 1,
          propertyPurchaseTypeId: propertyDetail?.propertyPurchaseTypeId,
          paymentResponse: res,
        };

        let result = "";
        if (propertyDetail?.propertyPurchaseTypeId == 2) {
          result = await insertBookingDetailsAction(arr);
        } else if (propertyDetail?.propertyPurchaseTypeId == 3) {
          result = await insertBookingDetailsAction(arr1);
        } else {
          result = await insertBookingDetailsAction(arr3);
        }
        if (result.success) {
          toast.success(result.msg);
          setTimeout(() => {
            navigate(`${config.baseUrl}userdashboard/propertyBooking`);
          }, 2000);
        } else {
          toast.error(result.msg);
        }
      } else {
        toast.error(
          "Something went wrong with your payment. Please try again after some time"
        );
      }
    });
  };

  const onError = (data, actions) => {
    toast.error(
      "Something went wrong with your payment. Please try again after some time"
    );
  };

  const disableStripe = () => {
    setClientSecret("");
  };

  useMemo(() => {
    if (paymentType == 2) {
      paymentIntent();
    }
  }, [paymentType]);

  const callFygaro = () => {
    if (fygaroToken) {
      let fygaroString = `https://www.fygaro.com/en/pb/8ef036d5-3406-4b0d-858f-8d814667d469?jwt=${fygaroToken}`;
      window.open(fygaroString, '_blank');
    } else {
      toast.error('Something went wrong please try again after some time.');
    }
  };

  return (
    <>
      <Header />
      <Toaster />
      <section className="yourbooking pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <div className="">
            <Row className=" ">
              <Col lg={5} className="mb-4">
                <div className="heading border-bottom border-secondary pt-2 pb-2">
                  <h5>
                    <b>Payment</b>
                  </h5>
                </div>
                <div className="mt-4">
                  <h6 className="mb-2">
                    <b>Pay With:</b>
                  </h6>
                  <Form>
                    {["radio"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="PayPal"
                          onChange={(e) => setpaymentType(1)}
                          checked={paymentType == 1}
                          name="group1"
                          type={type}
                          id={`inline-${type}-3`}
                          className="me-3"
                        />

                      </div>
                    ))}
                  </Form>
                </div>

                {paymentType == 1 && roomPrice > 0 ? (
                  <PayPalScriptProvider
                    options={{ "client-id": config.CLIENT_ID }}
                    className="pglk"
                  >
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      className="paypal-buttons"
                      onClick={disableStripe}
                      createOrder={createOrder}
                      onApprove={onApprove}
                      onError={onError}
                      fundingSource={FUNDING.PAYPAL}
                    />
                  </PayPalScriptProvider>
                ) : clientSecret && paymentType == 2 ? (
                  <Elements stripe={stripePromise} options={clientSecret}>
                    <div className="text-center" style={{ padding: "15px" }}>
                      <CheckoutForm
                        data={{
                          amount: orderAmount,
                          id: id,
                          numberOfAdults: numberOfAdults,
                          checkInDate: checkin,
                          checkOutDate: checkout,
                          roomType: roomType,
                          numberOfChildren: numberOfChildren,
                          numberOfInfants: numberOfInfants,
                          numberOfPets: numberOfPets,
                          propertyPurchaseTypeId:
                            propertyDetail?.propertyPurchaseTypeId,
                        }}
                      />
                    </div>
                  </Elements>
                ) : (
                  ""
                )}

                <button className="btn paypal-button w-100 mt-2" onClick={callFygaro}>Fygaro</button>
                
              </Col>
              <Col lg={2}></Col>
              <Col lg={5} className="mb-4">
                <Card>
                  <div className="paymentImg">
                    {imagesArr.length > 0 ? (
                      <img src={imagesArr[0].fileNameImage} />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="px-2">
                    <div>
                      <div className="mt-4">
                        <div className="">
                          <h5 className="mb-2">
                            <b>{propertyDetail.propertyName}</b>
                          </h5>
                          <div className="d-flex justify-content-between">
                            <div>
                              <h6>{propertyDetail.location}</h6>
                            </div>
                            {propertyDetail?.propertyPurchaseTypeId !== 2 ? (
                              <h5>
                                {" "}
                                <b>${totalAmount}</b>
                              </h5>
                            ) : (
                              ""
                            )}
                          </div>
                          {propertyDetail?.propertyPurchaseTypeId == 2 ? (
                            <div>
                              <p className="text-grey mb-0">
                                {moment(new Date()).format("YYYY-MM-DD")}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <hr />

                      {propertyDetail?.propertyPurchaseTypeId == 2 ? (
                        <>
                          <div className="">
                            <Row>
                              <Col lg={4} className="">
                                <div>
                                  <div>
                                    <b>Check-in</b>
                                  </div>
                                  <p className="text-grey mb-0">{checkin}</p>
                                </div>
                              </Col>
                              <Col lg={4} className="">
                                <div>
                                  <div>
                                    <b>Checkout</b>
                                  </div>
                                  <p className="text-grey mb-0">{checkout}</p>
                                </div>
                              </Col>
                              <Col lg={4} className="">
                                <div>
                                  <div>
                                    <b>Guests</b>
                                  </div>
                                  <p className="text-grey mb-0">
                                    {numberOfAdults} Adult, {numberOfChildren}{" "}
                                    Children, {numberOfInfants} Infants,{" "}
                                    {numberOfPets} Pets
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <hr />
                          <div className="mt-4 mb-1">
                            <Row>
                              <Col lg={6} sm={6} xs={6} className="mb-2">
                                <h6>
                                  <td>
                                    {" "}
                                    {parseFloat(numberOfAdults) / 2 -
                                      parseInt(numberOfAdults / 2) >
                                      0
                                      ? parseInt(
                                        parseFloat(numberOfAdults) / 2
                                      ) + 1
                                      : parseFloat(numberOfAdults) / 2}{" "}
                                    Beds X {parseFloat(roomPrice)} Price X{" "}
                                    {totalBookingDay} Nights
                                  </td>
                                </h6>
                              </Col>
                              <Col
                                lg={6}
                                sm={6}
                                xs={6}
                                className="mb-2 text-right"
                              >
                                <span className="text-grey">
                                  $
                                  {(parseFloat(numberOfAdults) / 2 -
                                    parseInt(numberOfAdults / 2) >
                                    0
                                    ? parseInt(parseFloat(numberOfAdults) / 2) +
                                    1
                                    : parseFloat(numberOfAdults) / 2) *
                                    parseFloat(roomPrice) *
                                    totalBookingDay}
                                </span>
                              </Col>
                              <Col lg={6} sm={6} xs={6} className="mb-2">
                                <h6>Cleaning and service fee</h6>
                              </Col>
                              <Col
                                lg={6}
                                sm={6}
                                xs={6}
                                className="mb-2 text-right"
                              >
                                <span className="text-grey">
                                  + $
                                  {propertyDetail?.cleaningAndMaintenanceFee
                                    ? propertyDetail?.cleaningAndMaintenanceFee
                                    : 0}
                                </span>
                              </Col>
                              <Col lg={6} sm={6} xs={6} className="mb-2">
                                <h6>Pets Amount</h6>
                              </Col>
                              <Col
                                lg={6}
                                sm={6}
                                xs={6}
                                className="mb-2 text-right"
                              >
                                <span className="text-grey">
                                  + $
                                  {parseFloat(
                                    propertyDetail?.petsPrice &&
                                      numberOfPets > 0
                                      ? parseInt(numberOfPets) *
                                      parseFloat(propertyDetail?.petsPrice)
                                      : 0
                                  )}
                                </span>
                              </Col>

                              <Col lg={6} sm={6} xs={6} className="mb-2">
                                <h6>Taxes</h6>
                              </Col>
                              <Col
                                lg={6}
                                sm={6}
                                xs={6}
                                className="mb-2 text-right"
                              >
                                <span className="text-grey">
                                  + $
                                  {propertyDetail?.taxPercentage
                                    ? ((parseFloat(numberOfAdults) / 2 -
                                      parseInt(numberOfAdults / 2) >
                                      0
                                      ? parseInt(
                                        parseFloat(numberOfAdults) / 2
                                      ) + 1
                                      : parseFloat(numberOfAdults) / 2) *
                                      parseFloat(roomPrice) *
                                      totalBookingDay *
                                      parseFloat(
                                        propertyDetail?.taxPercentage
                                      )) /
                                    100
                                    : 0}
                                </span>
                              </Col>

                              <Col lg={6} sm={6} xs={6} className="mb-2">
                                <h6>Security Deposit</h6>
                              </Col>
                              <Col
                                lg={6}
                                sm={6}
                                xs={6}
                                className="mb-2 text-right"
                              >
                                <span className="text-grey">
                                  + ${propertyDetail?.securityDeposit}
                                </span>
                              </Col>

                              <Col lg={6} sm={6} xs={6} className="mb-2 ">
                                <h5>
                                  <b>Total Cost:</b>
                                </h5>
                              </Col>
                              <Col
                                lg={6}
                                sm={6}
                                xs={6}
                                className="mb-2 text-right"
                              >
                                <h5 className="fw-bold">
                                  $
                                  {(parseFloat(numberOfAdults) / 2 -
                                    parseInt(numberOfAdults / 2) >
                                    0
                                    ? parseInt(parseFloat(numberOfAdults) / 2) +
                                    1
                                    : parseFloat(numberOfAdults) / 2) *
                                    parseFloat(roomPrice) *
                                    totalBookingDay +
                                    (propertyDetail?.taxPercentage
                                      ? ((parseFloat(numberOfAdults) / 2 -
                                        parseInt(numberOfAdults / 2) >
                                        0
                                        ? parseInt(
                                          parseFloat(numberOfAdults) / 2
                                        ) + 1
                                        : parseFloat(numberOfAdults) / 2) *
                                        parseFloat(roomPrice) *
                                        totalBookingDay *
                                        parseFloat(
                                          propertyDetail?.taxPercentage
                                        )) /
                                      100
                                      : 0) +
                                    parseFloat(
                                      propertyDetail?.cleaningAndMaintenanceFee &&
                                        numberOfAdults > 0
                                        ? propertyDetail?.cleaningAndMaintenanceFee
                                        : 0
                                    ) +
                                    parseFloat(
                                      propertyDetail?.petsPrice &&
                                        numberOfPets > 0
                                        ? parseInt(numberOfPets) *
                                        parseFloat(
                                          propertyDetail?.petsPrice
                                        )
                                        : 0
                                    ) +
                                    parseFloat(propertyDetail?.securityDeposit)}
                                </h5>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Payment;
